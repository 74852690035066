import React from "react";
import { withTranslation } from "react-i18next";
import InstagramWidget from "../InstagramWidget"
import SocialShare from "../SocialShare"
import QuestionBubble from "../QuestionBubble"
import SmartForm from "../SmartForm"
import Heading from "../Heading"
import './wedding.scss'
import Meta from "../Meta";
class Wedding extends React.Component {

    render() {

        const { data, instaFeed, content, t } = this.props
        return(
            <div id="wedding" class="blog-area blog-no-sidebar pt-100 pb-100">
            <Meta
                keywords={data.keywords}
                description={data.description}
            />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                            </div>
                            <div class="row mt-40">
                                <div class="col-md-6">
                                    <blockquote class="mb-50">
                                        {data.blockquote}
                                    </blockquote>
                                </div>
                                <div class="col-md-6">
                                    <QuestionBubble 
                                        title={data.questionTitle} 
                                        description={data.questionDescription} 
                                        phone={data.questionPhone}  
                                        email={data.questionEmail} />
                                </div>
                            </div>
                            <div class="question-area">
                                <h3>{t('would_you_like_to_request_an_offer?')}</h3>
                                <SmartForm 
                                    successTitle={t('your_offer_request_has_been_sent_successfully')}
                                    successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                    formClassName="wedding-form" 
                                    formName="wedding" 
                                    mailSubject="Tiliana esküvő ajánlatkérés"
                                    stateProps={["name", "email", "phone", "message"]}>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label>{t('my_name')}</label>
                                                <input name="name" type="text" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label>{t('my_email')}</label>
                                                <input name="email" type="email" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label>{t('my_phone')}</label>
                                                <input name="phone" type="phone" required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="single-input">
                                                <label>{t('offer_request')}</label>
                                                <textarea className="form-control" name="message" required />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="single-input">
                                                <button className="sent-btn" type="submit">
                                                    {t('request_offer')}
                                                </button>
                                            </div>
                                        </div>                                   
                                    </div>
                                </SmartForm>
                            </div>
                            <div class="common-tag-and-next-prev mt-60">
                                <SocialShare title={data.title} hashtags="lastminutewedding" />
                            </div>
                            <div class="row w-100">
                                <div className="widget mt-60">
                                    <InstagramWidget data={instaFeed} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Wedding)